import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { gql, useQuery } from '@apollo/client';
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase';
import { Box, Typography, Link, Icon, Modal } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import moneroIcon from './resources/monero-xmr-logo.svg';
import moneroQR from './resources/moneroqr.png';
import './App.scss'
import { useState } from 'react';
import MdToHtml from './components/mdToHtml';

const authorQuery = gql`
  query MyQuery {
    author(where: {slug: "jodios"}) {
      name
      bio
    }
  }
`;

function App() {

  const { data, loading, error } = useQuery(authorQuery);
  const [open, setOpen] = useState(false);

  const handleChange = () => {
    if (!open) {
      logLinkClick('monero');
    }
    setOpen(!open);
  }
  const logLinkClick = (name) => {
    logEvent(analytics, "click", { "type": "link", "to": name })
  }
  const linkedinURL = "https://www.linkedin.com/in/joel-ortiz-a3460ba3/";
  const githubUrl = "https://github.com/jodios";

  return (
    <Box className='App'>
      <section>
        <Grid id='sectionContent' textAlign='center' direction='column' container>
          <Typography variant='h2' mb={15} textAlign="center">
            JODIOS
          </Typography>
          <Grid justifyContent='center' container gap={1}>
            <Link target='_blank' href={linkedinURL} onClick={() => { logLinkClick("LinkedIn") }}>
              <LinkedInIcon id='iconLink' />
            </Link>
            <Link target='_blank' href={githubUrl} onClick={() => { logLinkClick("Github") }}>
              <GitHubIcon id='iconLink' />
            </Link>
            <Grid>
              <Icon sx={{ cursor: 'pointer' }} onClick={handleChange}>
                <img src={moneroIcon} />
              </Icon>
              <Modal
                open={open}
              >
                <Grid
                  textAlign='center'
                  alignContent='center'
                  width='100%'
                  height='100%'
                  onClick={handleChange}
                >
                  <img src={moneroQR} />
                  <Grid width='50%' sx={{ wordWrap: 'break-word', ml: 'auto', mr: 'auto' }}>
                    <Typography>
                      84Rdjas61zK2ggoEPfbUX64Jd9Wv2DimaJRiT8igPYPQjWmJ8swKnuiJm1w6iHMAdfKoK96r3LArxMPMq2KmP5TcTrGHD4D
                    </Typography>
                  </Grid>
                </Grid>
              </Modal>
            </Grid>
          </Grid>
          <Grid>
            <Link href="/blog" id='link' mr={1} onClick={() => { logLinkClick("Blog") }}>
              Blog
            </Link>
            <Link href="#aboutSection" id='link' onClick={() => { logLinkClick("About") }}>
              About
            </Link>
          </Grid>
        </Grid>
      </section>
      <section id='aboutSection'>
        <Box id='sectionContent'>
          {error && "Something went wrong..."}
          {!loading &&
            <>
              <MdToHtml content={data.author.bio} />
              <br />
              - {data.author.name}
            </>
          }
        </Box>
      </section>
    </Box>
  );
}

export default App;
