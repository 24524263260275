import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';
import Grid from '@mui/material/Unstable_Grid2';

const PostQuery = gql`
    query MyQuery {
        posts(orderBy:publishedAt_DESC) {
            slug
            tags {
                tag
            }
            title
            publishedAt
            authors {
                name
            }
            coverImage {
                url
            }
        }
    }
`;

const BlogContent = ({ children, className }) => {
    return (
        <Grid className={className}>
            <section>
                <Grid id='sectionContent'>
                    {children}
                </Grid>
            </section>
        </Grid>
    )
};

const Blog = () => {

    const { loading, error, data } = useQuery(PostQuery);
    const navigate = useNavigate();
    const theme = useTheme();
    const md = !useMediaQuery(theme.breakpoints.up('md'));

    const handleCardClick = (slug) => {
        logEvent(analytics, "click", { "type": "blog_post", "to": slug })
        navigate(`/blog/${slug}`);
    }

    if (error) {
        logEvent(analytics, "exception", { "description": "failed to retrieve blog posts" })
        return (
            <BlogContent>
                ERROR...
                {JSON.stringify(error)}
            </BlogContent>
        )
    }

    if (loading) {
        return (
            <BlogContent>
                {Array.from(Array(3).keys()).map((n) => {
                    return (
                        <Card sx={{ height: '20vh', p: 2, mb: 2 }} key={n}>
                            <Skeleton
                                width='25%'
                                height='30%'
                                animation='pulse'
                            />
                            <Skeleton variant='text' />
                            <Skeleton variant='text' />
                            <Skeleton variant='rectangular' />
                        </Card>
                    )
                })}
            </BlogContent>
        )
    }

    return (
        <BlogContent className="Blog">
            {data.posts && data.posts.map((post, i) =>
                <Card key={i} sx={{
                    display: 'flex',
                    marginBottom: 2,
                    padding: 2,
                }}>
                    <Box sx={{ display: 'flex', flexDirection: md ? 'column' : 'row' }}>
                        <CardMedia
                            component='img'
                            image={post.coverImage.url}
                            sx={{ maxWidth: md ? '100%' : '45%' }}
                        />
                        <CardContent>
                            <Typography component='div' variant='h5'>
                                {post.title}
                            </Typography>
                            <Box sx={{ marginTop: 3 }}>
                                <Button onClick={() => { handleCardClick(post.slug) }}>Read more...</Button>
                            </Box>
                        </CardContent>
                    </Box>
                </Card>
            )}
        </BlogContent>
    )
}

export default Blog