import React, { useEffect, useState } from 'react'
import { Breadcrumbs, Container, Link, Typography } from '@mui/material';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';

const Navigation = () => {

    const location = useLocation()
    const [paths, setPaths] = useState([]);

    useEffect(() => {
        setPaths(location.pathname.split("/").filter(p => p !== ""))
    }, [location]);

    if (location.pathname == "/") {
        return null;
    }

    const getTo = (i) => {
        return `/${paths.slice(0, i + 1).join("/")}`;
    }

    return (
        <Grid className="Navigation" p={3}>
            <Breadcrumbs>
                <Link component={RouterLink}>
                    <Typography variant='overline'>Jodios</Typography>
                </Link>
                {paths.map((path, i) =>
                    <Link key={i} component={RouterLink} to={getTo(i)}>
                        <Typography variant='overline'>
                            {path}
                        </Typography>
                    </Link>
                )}
            </Breadcrumbs>
        </Grid>
    );
}

export default Navigation;