import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom';
import Blog from './blog';
import NotFound from './notFound';
import Navigation from './navigation/navigation';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import Post from './post';
import './firebase';
import { Container, createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';

import Nunito from './fonts/Nunito-VariableFont_wght.ttf';
import './index.scss';

console.log(`%c
     _  ___  ____ ___ ___  ____  
    | |\/ _ \\|  _ \\_ _/ _ \\/ ___| 
 _  | | | | | | | | | | | \\___ \\ 
| |_| | |_| | |_| | | |_| |___) |
 \\___/ \\___/|____/___\\___/|____/ 
`, 'color: #1976d2')

const client = new ApolloClient({
  uri: 'https://api-us-west-2.graphcms.com/v2/cl3nsb6j5481m01xi8zyq9t7l/master',
  cache: new InMemoryCache(),
});

const Layout = () => {
  const darkMode = useMediaQuery('(prefers-color-scheme: dark)');
  let theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
    typography: {
      fontSize: 20,
      fontFamily: 'Nunito',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Nunito';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: url(${Nunito})
          }
        `,
      },
      MuiLink: {
        defaultProps: {
          sx: {
            color: darkMode ? grey[100] : grey[900],
          },
        },
      },
    },
  })
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        <Navigation />
        <Outlet />
      </Container>
    </ThemeProvider>
  );
};

const router = createBrowserRouter([
  {
    element: (<Layout />),
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/blog/:slug",
        element: <Post />,
      },
      {
        path: "/*",
        element: <NotFound />,
      },

    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
