import React from 'react';
import {
    Divider,
    Typography,
    useTheme,
    useMediaQuery,
    Button,
    Stack,
    Link,
    List,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Skeleton
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';
import Grid from '@mui/material/Unstable_Grid2';
import { ArrowRight } from '@mui/icons-material';

// import 'katex/dist/katex.min.css';
import './post.scss';
import MdToHtml from '../components/mdToHtml';

const postQuery = (slug) => gql`
    query MyQuery {
        post(where: {slug: "${slug}"}) {
            authors {
                name
            }
            files {
                fileName
                url
            }
            coverImage {
                url
            }
            content
            publishedAt
            slug
            tags {
                tag
            }
            title
            updatedAt
        }
    }
`;

const Post = () => {
    const { slug } = useParams();
    const { loading, error, data } = useQuery(postQuery(slug));
    const theme = useTheme();
    const md = !useMediaQuery(theme.breakpoints.up('md'));

    const download = (url, name) => {
        logEvent(analytics, "file_download", { "description": `downloading ${name} from ${slug}` })
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const blobURL = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = blobURL;
                a.style = "display: none";

                if (name && name.length) a.download = name;
                document.body.appendChild(a);
                a.click();
            })
            .catch((e) => console.log("Failed to download: ", e));
    }

    if (error) {
        logEvent(analytics, "exception", { "description": `failed to load post: ${slug}` })
        return (
            <div className='Post Container'>
                <section>
                    <div id='sectionContent'>
                        ERROR...
                        {JSON.stringify(error)}
                    </div>
                </section>
            </div>
        )
    }

    if (loading) {
        return (
            <Grid className='Post'>
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    height='25vh'
                    sx={{ mb: 2 }}
                />
                <Skeleton
                    variant='text'
                    animation='pulse'
                />
                <Skeleton
                    variant='text'
                    animation='pulse'
                />
                <Skeleton
                    variant='text'
                    animation='pulse'
                    width='50%'
                />
            </Grid>
        )
    }

    const generateSlug = (string) => {
        let str = string.replace(/^\s+|\s+$/g, "");
        str = str.toLowerCase();
        str = str
            .replace(/[^a-z0-9 -]/g, "")
            .replace(/\s+/g, "-")
            .replace(/-+/g, "-");

        return str;
    };

    const tableOfConent = [...data.post.content.matchAll('(###.*|##.*)')].map(result => {
        const heading = result[0].replace("###", "").replace("##", "").trim();
        const href = `#${generateSlug(heading)}`
        return (
            <ListItemButton sx={{ width: 'fit-content' }}>
                <ListItemIcon>
                    <ArrowRight />
                </ListItemIcon>
                <ListItemText>
                    <Link href={href}>{heading}</Link>
                </ListItemText>
            </ListItemButton>
        );
    });

    return (
        <Grid className='Post' container justifyContent='center' p={md ? 1 : 2} textAlign='left'>
            <Grid textAlign='center'>
                <img src={data.post.coverImage.url} style={{ maxWidth: '60%' }} />
            </Grid>
            <Grid width='100%'>
                <Typography variant={md ? 'h6' : 'h4'}>{data.post.title}</Typography>
            </Grid>
            <Grid width='100%'>
                <Stack direction='row' alignItems='center' gap={1} mb={1}>
                    <PermIdentityIcon id="icon" />
                    <Typography>
                        {data.post.authors[0].name}
                    </Typography>
                </Stack>
                <Stack direction='row' alignItems='center' gap={1} mb={2}>
                    <CalendarTodayIcon id="icon" />
                    {new Date(data.post.publishedAt).toLocaleDateString("en-us").replaceAll("/", "-")}
                </Stack>
            </Grid>
            <Grid id="heading" width='100%' direction='row' container>
                {data.post.files.length > 0 && data.post.files.map((file, i) =>
                    <Grid key={i} id="File">
                        <Button
                            component="label"
                            variant='text'
                            startIcon={<FolderOpenIcon />}
                            sx={{ maxHeight: 30 }}
                            onClick={() => (download(file.url, file.fileName))}
                        >
                            {file.fileName}
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Divider id="divider" sx={{ width: '100%', mb: 2, mt: 2 }} />
            <Grid width='100%'>
                <Typography variant='h6'>Table of content</Typography>
                <List>{tableOfConent}</List>
            </Grid>
            <Grid>
                <MdToHtml content={data.post.content} />
            </Grid>
        </Grid>
    );
}

export default Post;