import React, { useEffect, useState } from 'react';
import {
    Divider,
    Typography,
    Link,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Paper,
    useMediaQuery,
} from '@mui/material';
import {
    duotoneLight as lightCodeStyle,
    duotoneDark as darkCodeStyle,
} from 'react-syntax-highlighter/dist/esm/styles/prism'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import Markdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
const MdToHtml = ({ content }) => {

    const darkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [codeStyle, setCodeStyle] = useState(lightCodeStyle);

    useEffect(() => {
        setCodeStyle(darkMode ? darkCodeStyle : lightCodeStyle);
    }, [darkMode]);

    const generateSlug = (string) => {
        let str = string.replace(/^\s+|\s+$/g, "");
        str = str.toLowerCase();
        str = str
            .replace(/[^a-z0-9 -]/g, "")
            .replace(/\s+/g, "-")
            .replace(/-+/g, "-");
        const heading = {
            title: string,
            href: str,
        };

        return str;
    };

    return (
        <Markdown
            remarkPlugins={[remarkMath, remarkGfm]}
            rehypePlugins={[rehypeKatex]}
            children={content}
            components={{
                code(props) {
                    const { children, className, node, ...rest } = props
                    const match = /language-(\w+)/.exec(className || '')
                    return match ? (
                        <SyntaxHighlighter
                            {...rest}
                            PreTag="div"
                            children={String(children).replace(/\n$/, '')}
                            language={match[1]}
                            style={codeStyle}
                        />
                    ) : (
                        <code {...rest} className={className}>
                            {children}
                        </code>
                    )
                },
                h3: ({ node, ...props }) => {
                    return (
                        <>
                            <Typography fontWeight='bold' variant='h6' id={generateSlug(props.children)} {...props} mt={4} mb={1}></Typography>
                        </>
                    )
                },
                h2: ({ node, ...props }) => {
                    return (
                        <>
                            <Typography fontWeight='bold' variant='h4' id={generateSlug(props.children)} {...props} mt={4} mb={1}></Typography>
                            <Divider id="divider" sx={{ width: '100%', mb: 2, mt: 2 }} />
                        </>
                    )
                },
                p: ({ node, ...props }) => {
                    return (
                        <Typography {...props} />
                    )
                },
                table: ({ node, ...props }) => {
                    return (
                        <TableContainer component={Paper}>
                            <Table {...props} />
                        </TableContainer>
                    )
                },
                thead: ({ node, ...props }) => {
                    return (
                        <TableHead {...props} />
                    )
                },
                tbody: ({ node, ...props }) => {
                    return (
                        <TableBody {...props} />
                    )
                },
                tr: ({ node, ...props }) => {
                    return (
                        <TableRow {...props} />
                    )
                },
                th: ({ node, ...props }) => {
                    return (
                        <TableCell sx={{ fontWeight: 'bold' }} {...props} />
                    )
                },
                td: ({ node, ...props }) => {
                    return (
                        <TableCell {...props} />
                    )
                },
                img: ({ node, ...props }) => {
                    return (
                        <img {...props} style={{ maxWidth: '100%' }} />
                    )
                },
                a: ({ node, ...props }) => {
                    return (
                        <Link component='a' {...props} />
                    )
                },
            }}
        />
    )
}
export default MdToHtml;